@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');


* {
    font-family: 'Rubik', sans-serif;
}

body {
    background-color: #f9f9fa;
}

.gradient-bg {
    fill: url(#header-shape-gradient) #fff;
  }
  
  #header-shape-gradient {
    --color-stop: #15ad3b;
    --color-bot: #29f55c;
  }